<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
      <nav class="flex" aria-label="Breadcrumb">
        <ol class="flex items-center space-x-2 w-full">
          <li>
            <div @click="$router.push('/')" class="text-gray-400 hover:text-gray-500 cursor-pointer">
              <svg class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
              <a @click="$router.push('/produtos')" class="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer">Produtos</a>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              </svg>
              <a class="ml-2 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">{{ product.title }}</a>
            </div>
          </li>
        </ol>
      </nav>
      <div class="mt-6 grid grid-cols-2 gap-10">
        <div class="col-span-2 md:col-span-1 flex justify-center rounded-xl border-2 p-3">
          <img class="max-h-96 object-contain" :src="product.image" alt="" />
        </div>
        <div class="col-span-2 md:col-span-1">
          <p v-if="disabled" class="bg-red-500 w-min text-white rounded-full px-3 font-medium">INDISPONÍVEL</p>
          <h2 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">{{ product.title }}</h2>
          <p class="mt-1 text-sm text-gray-500">em <span class="text-primary">{{ product.categorie }}</span></p>
          <div class="pt-2 flex items-center space-x-4 my-4" :class="disabled ? 'opacity-50' : 'opacity-100'">
            <div class="rounded-lg bg-gray-100 flex py-2 px-3 font-bold text-primarylight text-2xl">{{ product.priceFinal | currency }}</div>
            <div class="flex-1">
              <p class="text-green-500 text-lg font-semibold">Economize {{ parseInt(100 - ((product.priceFinal * 100) / product.priceML)) }}%</p>
              <p class="text-gray-400 text-sm line-through">{{ product.priceML | currency }}</p>
            </div>
          </div>
          <p class="text-sm text-gray-500 text-justify py-4">{{ product.description }}</p>
          <div class="mt-4 grid grid-cols-2 gap-4" :class="disabled ? 'opacity-50' : 'opacity-100'">
            <div class="col-span-2 md:col-span-1">
              <button @click="disabled ? '' : addCart(product.id)" :disabled="disabled" type="button" class="w-full truncate inline-flex items-center justify-center px-4 py-2 border-2 border-primary shadow-sm text-base font-medium rounded-md text-primary bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-3 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                Adicionar ao carrinho
              </button>
            </div>
            <div class="col-span-2 md:col-span-1">
              <button @click="disabled ? '' : addCart(product.id), $router.push('/carrinho')" :disabled="disabled" type="button" class="w-full px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                Comprar agora
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="product.attachments && product.attachments.length">
        <label for="info" class="mt-5 block text-sm font-medium text-gray-700">Outras informações</label>
        <dd class="mt-3 text-sm text-gray-900">
          <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
            <li v-for="(item, index) in product.attachments" :key="index" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div class="w-0 flex-1 flex items-center">
                <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                </svg>
                <span class="ml-2 flex-1 w-0 truncate">
                  {{ item.identify }}
                </span>
              </div>
              <div @click="goPdf(item.path)" class="ml-4 flex-shrink-0 font-medium text-primary hover:text-secundary">Visualizar</div>
            </li>
          </ul>
        </dd>
      </div>
      <div v-if="product.optionals && optionals.length">
        <label for="info" class="mt-5 block text-sm font-medium text-gray-700">Produtos Opcionais</label>
        <div class="mt-5 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none" style="filter: invert(3%);">
          <div @click="goProduct(item.id)" v-for="(item, index) in optionals" :key="index" class="bg-white flex flex-col rounded-lg shadow hover:shadow-lg overflow-hidden">
            <div class="relative">
              <img class="h-56 w-full object-contain" :src="item.image" alt="">
              <span class="absolute bottom-2 left-4 px-2.5 py-0.5 rounded-md text-sm font-medium bg-primary text-white">{{ item.price | currency }}</span>
            </div>
            <div class="p-4 flex items-center">
              <div class="w-4/5">
                <div class="inline-flex items-center text-sm font-medium text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                  </svg>
                  <span class="ml-1 antialiased">{{ item.categorie }}</span>
                </div>
                <p class="text-xl font-semibold text-gray-900 truncate">
                  {{ item.titleDestaque || item.title }}
                </p>
              </div>
              <div class="w-full text-right">
                <button @click.stop="addCart(item.id)" type="button" class="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import idb from '@/idb'
import { listingsColl, productsColl } from '@/firebase'

export default {
  data() {
    return {
      product: {},
      products: [],
      selected: '',
      select: false,
      expand: false,
      disabled: false,
    }
  },

  computed: {
    optionals: function() {
      return this.products.filter(item => this.product.optionals.includes(item.title))
    }
  },

  mounted() { this.getProduct(this.$router.currentRoute.params.id), this.getProducts() },

  methods: {
    async getProducts() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await listingsColl.doc('Products').get()
      .then((result) => { this.products = result.data().available })
      .catch(() => { this.$toast.error('Falha ao obter os produtos, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
      const item = this.products.find(item => item.id === this.$router.currentRoute.params.id)
      this.disabled = item.unavailable || item.invisible || false
    },

    async getProduct(id) {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await productsColl.doc(id).get()
      .then((result) => { this.product = result.data(); this.product.id = id })
      .catch(() => { this.$toast.error('Falha ao obter o produto, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async addCart(item) {
      await idb.saveField(item, '1')
      this.$toast.success('Produto adicionado ao carrinho')
    },
    
    goPdf(path) {
      window.open(path, '_blank')
    },

    goProduct(id) {
      window.open(this.$router.resolve(`/produto/${id}`).href, '_blank');
    }
  },

  watch: {
    '$route'() {
      this.getProduct(this.$router.currentRoute.params.id)
    }
  },

  filters: {
    currency(val) {
      var currency = parseFloat(val)
      return currency.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
    }
  }
}
</script>
